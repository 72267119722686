<template>
  <v-container>
    <v-row>
      <v-col
        class="py-0"
        cols="12"
      >
        <new-beneficiary v-if="showNewModal" />
        <edit-beneficiary v-if="showEditModal" />
        <app-data-table
          :title="
            $vuetify.lang.t('$vuetify.titles.list', [
              $vuetify.lang.t('$vuetify.menu.admin_beneficiary')
            ])
          "
          csv-filename="Categories"
          :headers="getTableColumns"
          :items="beneficiaries"
          :manager="'plan'"
          :sort-by="['firstName']"
          :sort-desc="[false, true]"
          multi-sort
          @create-row="toogleNewModal(true)"
          @edit-row="editBeneficiaryHandler($event)"
          @delete-row="deleteBeneficiaryHandler($event)"
        >
          <template v-slot:[`item.firstName`]="{ item }">
            <v-avatar>
              <v-img
                :src="item.avatar ? item.avatar.original_url : `/assets/avatar/avatar-undefined.jpg`"
              />
            </v-avatar>
            {{ item.firstName }}
          </template>
          <template v-slot:[`item.isPartner`]="{ item }">
            <v-icon v-if="item.isPartner" color="primary">mdi-check-circle</v-icon>
            <v-icon v-else color="error">mdi-close-circle</v-icon>
          </template>
        </app-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import EditBeneficiary from './EditBeneficiary'
import NewBeneficiary from './NewBeneficiary'

export default {
  name: 'ListBeneficiary',
  components: {
    EditBeneficiary,
    NewBeneficiary
  },
  data () {
    return {
      search: ''
    }
  },
  computed: {
    ...mapState('beneficiary', [
      'showNewModal',
      'showEditModal',
      'showShowModal',
      'beneficiaries',
      'isTableLoading'
    ]),
    getTableColumns () {
      return [
        {
          text: this.$vuetify.lang.t('$vuetify.firstName'),
          value: 'firstName',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.lastName'),
          value: 'lastName',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.email'),
          value: 'email'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.menu.partners'),
          value: 'isPartner'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.phone'),
          value: 'phone',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.actions.actions'),
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  async created () {
    await this.getBeneficiariesAdmin()
  },
  methods: {
    ...mapActions('beneficiary', [
      'toogleNewModal',
      'openEditModal',
      'openShowModal',
      'getBeneficiariesAdmin',
      'deleteBeneficiary'
    ]),
    editBeneficiaryHandler ($event) {
      this.openEditModal($event)
    },
    deleteBeneficiaryHandler (beneficiaryId) {
      this.$Swal
        .fire({
          title: this.$vuetify.lang.t('$vuetify.titles.delete', [
            this.$vuetify.lang.t('$vuetify.menu.client')
          ]),
          text: this.$vuetify.lang.t(
            '$vuetify.messages.warning_delete'
          ),
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: this.$vuetify.lang.t(
            '$vuetify.actions.cancel'
          ),
          confirmButtonText: this.$vuetify.lang.t(
            '$vuetify.actions.delete'
          ),
          confirmButtonColor: 'red'
        })
        .then(result => {
          if (result.value) this.deleteBeneficiary(beneficiaryId)
        })
    }
  }
}
</script>

<style scoped></style>
